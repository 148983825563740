import { INotification, INotificationsState } from '@models';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as notificationsActions from '@store/actions/notifications';
import * as userActions from '@store/actions/user';

export const NAMESPACE = 'notifications';

export const adapter: EntityAdapter<INotification> =
  createEntityAdapter<INotification>({
    selectId: (entity: INotification) => entity.Id,
  });

export const initialState: INotificationsState = adapter.getInitialState({
  isLoading: false,
  showMore: false,
  total: 0,
  unread: 0,
});

export const NotificationsReducer = createReducer(
  initialState,
  on(
    notificationsActions.NotificationsRequest,
    notificationsActions.NotificationsReadRequest,
    notificationsActions.NotificationsDeleteRequest,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    notificationsActions.NotificationReadRequest,
    notificationsActions.NotificationDeleteRequest,
    (state, { notificationId }) => ({
      ...state,
      isLoading: true,
      ...adapter.updateOne(
        {
          id: notificationId,
          changes: {
            isLoading: true,
          },
        },
        state
      ),
    })
  ),
  on(
    notificationsActions.NotificationReadError,
    notificationsActions.NotificationDeleteError,
    (state, { notificationId }) => ({
      ...state,
      isLoading: true,
      ...adapter.updateOne(
        {
          id: notificationId,
          changes: {
            isLoading: false,
          },
        },
        state
      ),
    })
  ),
  on(
    notificationsActions.NotificationsError,
    notificationsActions.NotificationsReadError,
    notificationsActions.NotificationsDeleteError,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(
    notificationsActions.NotificationsHeadSuccess,
    (state, { total, unread }) => ({
      ...state,
      total,
      unread,
    })
  ),
  on(
    notificationsActions.NotificationsSuccess,
    (state, { notifications, reset, total, unread }) => {
      const { entities, ids } = reset
        ? adapter.setAll(notifications, state)
        : adapter.addMany(notifications, state);
      return {
        ...state,
        entities,
        ids,
        isLoading: false,
        total,
        unread,
        showMore: ids.length < total,
      };
    }
  ),
  on(
    notificationsActions.NotificationReadSuccess,
    (state, { notification }) => ({
      ...state,
      ...adapter.setOne(notification, state),
      unread: notification.IsRead ? state.unread - 1 : state.unread + 1,
      isLoading: false,
    })
  ),
  on(
    notificationsActions.NotificationDeleteSuccess,
    (state, { notificationId }) => {
      const deletedEntity = state.entities[notificationId];
      return {
        ...state,
        ...adapter.removeOne(notificationId, state),
        unread: deletedEntity.IsRead ? state.unread : state.unread - 1,
        isLoading: false,
      };
    }
  ),
  on(notificationsActions.NotificationsDeleteSuccess, (state) => ({
    ...state,
    ...adapter.removeAll(state),
    unread: 0,
    isLoading: false,
  })),
  on(notificationsActions.NotificationsReadSuccess, (state) => ({
    ...state,
    ...adapter.updateMany(
      state.ids.map((id) => ({
        id,
        changes: {
          IsRead: true,
        },
      })),
      state
    ),
    unread: 0,
    isLoading: false,
  })),
  on(userActions.LogoutSuccess, userActions.LoginSuccess, () => initialState)
);

export default NotificationsReducer;
